
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

.login-form {
	width: calc(100vw - 30px);
	max-width: 400px;
	height: auto;
	padding: 35px 0px;
	margin: auto;
	margin-top: 0;
	position: relative;
	top: 100vh;
	transform: translateY(-80vh);
	z-index: 2;
	background: #fff;
	color: #464646;
	border-radius: 5px;
	box-shadow: 1px 1px 3px 0px $shadow-gray;

	@media screen and (max-width: 480px) {
		margin-top: 15px;
		padding: 15px 25px;
	}

	@media screen and (max-width: 380px) {
		transform: translateY(-90vh);
	}
}
