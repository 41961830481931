
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

footer {
	width: 100vw;
	color: #47728a73;
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 1em;
	padding: 40px 0;
	position: relative;
	bottom: 0;

	@media screen and (max-width: 380px) {
		width: 78vw;
	}
}
