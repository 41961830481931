
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

header {
	background: $navy;
	width: 100vw;
	box-shadow: 0px -3px 6px 3px $shadow-gray;

	:deep(.header-container) {
		max-width: 800px;
		margin: auto;
	}

	:deep(img) {
		height: 34px;
		margin: 10px 0;
	}

	:deep(.logout-button) {
		float: right;
		margin: 12px 0;
		padding: 0 16px;

		&:hover,
		&:active,
		&:focus {
			background: #9b9ea1;
			opacity: 1;
		}
	}
}
