
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

.loading-overlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background: #fff;
	perspective: 800px;

	.spinner {
		display: block;
		width: 100px;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.spinner > div {
		width: 16px;
		height: 16px;
		margin: 0 4px;
		background: $aqua-gradient;
		border-radius: 100%;
		display: inline-block;
		animation: sk-bouncedelay 1.8s ease-in-out both infinite;
	}

	.spinner .bounce1 {
		animation-delay: 0.68s;
	}

	.spinner .bounce2 {
		animation-delay: 0.84s;
	}

	.spinner .bounce3 {
		animation-delay: 1s;
	}

	@keyframes sk-bouncedelay {
		0%,
		80%,
		100% {
			transform: scale(0);
		}
		40% {
			transform: scale(1);
		}
	}

	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
