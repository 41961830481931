.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.hidden {
	display: none;
}

.float-l {
	float: left;
}

.float-r {
	float: right;
}

.txt-l {
	text-align: left;
}

.txt-c {
	text-align: center;
}

.txt-r {
	text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// Margin Utilities

@mixin margin($values...) {
	@each $var in $values {
		margin: #{$var} !important;
	}
}

@mixin margin-top($value) {
	margin-top: $value !important;
}

@mixin margin-right($value) {
	margin-right: $value !important;
}

@mixin margin-bottom($value) {
	margin-bottom: $value !important;
}

@mixin margin-left($value) {
	margin-left: $value !important;
}

.m-0 {
	@include margin(0);
}

.m-5 {
	@include margin(5px);
}

.m-10 {
	@include margin(10px);
}

.m-15 {
	@include margin(15px);
}

.m-20 {
	@include margin(20px);
}

.m-30 {
	@include margin(30px);
}

.m-40 {
	@include margin(40px);
}

.mt-0 {
	@include margin-top(0);
}

.mt-5 {
	@include margin-top(5px);
}

.mt-10 {
	@include margin-top(10px);
}

.mt-15 {
	@include margin-top(15px);
}

.mt-20 {
	@include margin-top(20px);
}

.mt-30 {
	@include margin-top(30px);
}

.mt-40 {
	@include margin-top(40px);
}

.mr-0 {
	@include margin-right(0);
}

.mr-5 {
	@include margin-right(5px);
}

.mr-10 {
	@include margin-right(10px);
}

.mr-15 {
	@include margin-right(15px);
}

.mr-20 {
	@include margin-right(20px);
}

.mr-30 {
	@include margin-right(30px);
}

.mr-40 {
	@include margin-right(40px);
}

.mb-0 {
	@include margin-bottom(0);
}

.mb-5 {
	@include margin-bottom(5px);
}

.mb-10 {
	@include margin-bottom(10px);
}

.mb-15 {
	@include margin-bottom(15px);
}

.mb-20 {
	@include margin-bottom(20px);
}

.mb-30 {
	@include margin-bottom(30px);
}

.mb-40 {
	@include margin-bottom(40px);
}

.ml-0 {
	@include margin-left(0);
}

.ml-5 {
	@include margin-left(5px);
}

.ml-10 {
	@include margin-left(10px);
}

.ml-15 {
	@include margin-left(15px);
}

.ml-20 {
	@include margin-left(20px);
}

.ml-30 {
	@include margin-left(30px);
}

.ml-40 {
	@include margin-left(40px);
}